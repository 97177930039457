/* table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
} */

.vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

:root {
  --primary: #4885ed;
  --secondary: #627eea;
  --primary-hover: #1a66e8;
  --primary-dark: #0e3d8e;
  --rgba-primary-1: rgba(72, 133, 237, 0.1);
  --rgba-primary-2: rgba(72, 133, 237, 0.2);
  --rgba-primary-3: rgba(72, 133, 237, 0.3);
  --rgba-primary-4: rgba(72, 133, 237, 0.4);
  --rgba-primary-5: rgba(72, 133, 237, 0.5);
  --rgba-primary-6: rgba(72, 133, 237, 0.6);
  --rgba-primary-7: rgba(72, 133, 237, 0.7);
  --rgba-primary-8: rgba(72, 133, 237, 0.8);
  --rgba-primary-9: rgba(72, 133, 237, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #000;
}

:root {
  --bs-blue: #5e72e4;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #e83e8c;
  --bs-red: #ee3232;
  --bs-orange: #ff9900;
  --bs-yellow: #fffa6f;
  --bs-green: #297f00;
  --bs-teal: #20c997;
  --bs-cyan: #3065d0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #4885ed;
  --bs-secondary: #627eea;
  --bs-success: #68e365;
  --bs-info: #b48dd3;
  --bs-warning: #ffa755;
  --bs-danger: #f72b50;
  --bs-light: #c8c8c8;
  --bs-dark: #6e6e6e;
  --bs-font-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
}

.table-primary {
  --bs-table-bg: #dae7fb;
  --bs-table-striped-bg: #cfdbee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c4d0e2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cad6e8;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c4d0e2;
}

.btn-primary {
  color: #000;
  background-color: #4885ed;
  border-color: #4885ed;
}

.btn-primary:hover {
  color: #000;
  background-color: #6397f0;
  border-color: #5a91ef;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #000;
  background-color: #6397f0;
  border-color: #5a91ef;
  box-shadow: 0 0 0 0.25rem rgba(61, 113, 201, 0.5);
}
.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary,
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #000;
  background-color: #6d9df1;
  border-color: #5a91ef;
}
.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(61, 113, 201, 0.5);
}
.btn-primary:disabled,
.btn-primary.disabled {
  color: #000;
  background-color: #4885ed;
  border-color: #4885ed;
}

.btn-outline-primary {
  color: #4885ed;
  border-color: #4885ed;
}
.btn-outline-primary:hover {
  color: #000;
  background-color: #4885ed;
  border-color: #4885ed;
}
.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(72, 133, 237, 0.5);
}
.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  color: #000;
  background-color: #4885ed;
  border-color: #4885ed;
}
.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(72, 133, 237, 0.5);
}
.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: #4885ed;
  background-color: transparent;
}

.alert-primary {
  color: #2b508e;
  background-color: #dae7fb;
  border-color: #c8dafa;
}
.alert-primary .alert-link {
  color: #224072;
}

.list-group-item-primary {
  color: #2b508e;
  background-color: #dae7fb;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #2b508e;
  background-color: #c4d0e2;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #2b508e;
  border-color: #2b508e;
}

.link-primary {
  color: #4885ed;
}
.link-primary:hover,
.link-primary:focus {
  color: #6d9df1;
}

.border-primary {
  border-color: #4885ed !important;
}

.text-primary {
  color: #4885ed !important;
}

.bg-primary {
  background-color: #4885ed !important;
}

::selection {
  color: #fff;
  background: var(--primary);
}

.bg-primary {
  background-color: var(--primary) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.loader > span {
  font-size: 60px;
  color: var(--primary);
  font-family: 'franklin gothic medium', sans-serif;
  display: inline-block;
  animation: flip 2.6s infinite linear;
  transform-origin: 0 70%;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) 0 0;
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: #fff;
  border-bottom-color: #fff;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-group > .card:not(:first-child) .card-header {
  border-top-left-radius: 0;
}

.chatbox .card-header {
  background: #f4f7ff;
  padding: 15px 20px;
  justify-content: center;
}
.chatbox .card-header h6,
.chatbox .card-header .h6 {
  font-size: 15px;
}
.chatbox .card-header p {
  line-height: 1.2;
  font-size: 12px;
  color: #969ba0;
}

.card-header {
  border-color: #f5f5f5;
  position: relative;
  background: transparent;
  padding: 1.5rem 1.875rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 575px) {
  .card-header {
    padding: 1.25rem 1rem 1.25rem;
  }
}
[data-theme-version='dark'] .card-header {
  border-color: #2e2e42;
}
.card-header .card-title {
  margin-bottom: 0px;
}
.card-header .subtitle {
  padding-top: 5px;
  font-size: 14px;
  line-height: 1.5;
}

.card-header .date_picker {
  display: inline-block;
  padding: 8px;
  border: 1px solid #f5f5f5;
  cursor: pointer;
  border-radius: 0.375rem;
}

.card-header .border-0 {
  padding-bottom: 0;
}

.card-list .card-header {
  padding-top: 0;
  padding-bottom: 0;
}
.card-list .card-header .photo {
  overflow: hidden;
  border-radius: 5px;
  width: 100%;
}
.card-list.list-left .card-header {
  padding-left: 0;
}
.card-list.list-left .card-header .photo {
  margin: -15px 15px 0 -15px;
}

.card-list.list-right .card-header .photo img {
  width: 100%;
}
.card-list.list-center .card-header .photo {
  margin: -15px 0 0 0;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-title {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  text-transform: capitalize;
}
.card-title--large {
  font-size: 1.5rem;
}
.card-title--medium {
  font-size: 1rem;
}
.card-title--small {
  font-size: 0.875rem;
}

.card-header .card-title {
  margin-bottom: 0px;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  border-radius: 0.2rem;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-sm,
.btn-group-sm > .btn {
  font-size: 0.813rem !important;
  padding: 0.625rem 1rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.chatbox .card-body {
  padding: 1rem;
}

.card-body {
  padding: 1.875rem;
}

@media only screen and (max-width: 575px) {
  .card-body {
    padding: 1rem;
  }
}

.horizontal-card__menu .card-body {
  padding: 0;
  padding: 20px 30px;
}

.dez-widget-1 .card .card-body p {
  color: #f87533;
}

[data-theme-version='dark']
  .dataTables_wrapper
  .dataTables_paginate
  span
  .paginate_button.current,
[data-theme-version='dark']
  .dataTables_wrapper
  .dataTables_paginate
  span
  .paginate_button:hover {
  background: var(--primary);
  color: #fff !important;
}

.dataTables_wrapper .dataTables_paginate {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

.dataTables_wrapper .dataTables_paginate {
  align-items: center;
  display: flex;
  flex-flow: wrap;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.previous,
.dataTables_wrapper .dataTables_paginate .paginate_button.next {
  font-size: 18px;
  height: 50px;
  width: fit-content;
  border: 1px solid var(--primary);
  border-radius: 45px;
  padding: 0 20px;
  line-height: 50px;
  margin: 0 10px;
  display: inline-block;
  color: var(--primary) !important;
}
.dataTables_wrapper
  .dataTables_paginate
  .paginate_button.previous.current:hover,
.dataTables_wrapper
  .dataTables_paginate
  .paginate_button.previous.previous:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.previous.next:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.next.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.next.previous:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.next.next:hover {
  color: #fff !important;
  background: var(--primary) !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.previous.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.next.disabled {
  color: var(--primary) !important;
}
@media only screen and (max-width: 575px) {
  .dataTables_wrapper .dataTables_paginate .paginate_button.previous,
  .dataTables_wrapper .dataTables_paginate .paginate_button.next {
    height: 50px;
    width: fit-content;
    line-height: 50px;
    padding: 0 12px;
  }
}
.dataTables_wrapper .dataTables_paginate span .paginate_button {
  height: 50px;
  width: 50px;
  padding: 0;
  margin-left: 0px;
  margin: 0 2px;
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  border-radius: 45px;
  color: var(--primary) !important;
  background: var(--rgba-primary-1);
}
@media only screen and (max-width: 575px) {
  .dataTables_wrapper .dataTables_paginate span .paginate_button {
    height: 50px;
    width: 50px;
    line-height: 50px;
  }
}
.dataTables_wrapper .dataTables_paginate span .paginate_button.current,
.dataTables_wrapper .dataTables_paginate span .paginate_button:hover {
  color: var(--primary) !important;
}
.dataTables_wrapper .dataTables_paginate span .paginate_button.current:hover,
.dataTables_wrapper .dataTables_paginate span .paginate_button:hover:hover {
  color: #fff !important;
  background: var(--primary) !important;
}

.dataTables_wrapper input[type='search'],
.dataTables_wrapper input[type='text'],
.dataTables_wrapper select {
  border: 1px solid #e2e2e2;
  padding: 0.3rem 0.5rem;
  color: #715d5d;
  border-radius: 5px;
}
[data-theme-version='dark'] .dataTables_wrapper input[type='search'],
[data-theme-version='dark'] .dataTables_wrapper input[type='text'],
[data-theme-version='dark'] .dataTables_wrapper select {
  background: #171622;
  border-color: #2e2e42;
  color: #fff;
}

.dataTables_wrapper .dataTables_length {
  margin-bottom: 15px;
}
.dataTables_wrapper .dataTables_length .bootstrap-select {
  width: 80px !important;
  margin: 0 5px;
}

@media (max-width: 991.98px) {
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
    text-align: left;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  color: #969ba0 !important;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: #969ba0;
  border-radius: 1rem;
  padding: 2px 0;
  margin-bottom: 20px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  color: #969ba0 !important;
}

.dataTables_wrapper .dataTables_length .bootstrap-select .dropdown-toggle {
  font-size: 0.813rem !important;
  padding: 0.625rem 1rem;
}

[data-theme-version='dark']
  .dataTables_wrapper
  .dataTables_paginate
  span
  .paginate_button.current,
[data-theme-version='dark']
  .dataTables_wrapper
  .dataTables_paginate
  span
  .paginate_button:hover {
  background: var(--primary);
  color: #fff !important;
}

.w-100 {
  width: 100% !important;
}
